<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">권한 그룹 찾기</div>
            <ul class="content">
              <li class="item dateRange">
                <input-text
                  ref="input-search-groups-query"
                  v-model="searchGroupsQuery"
                  @keydown.enter.native="onSearchGroupsClick"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="visibleUpdateAuthorityGroupUsersPopup"
              @click.native="onSearchGroupsClick"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">권한 그룹 목록</div>
              <div class="header-caption">[{{ 0 }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="settings">
                  <erp-button
                      button-div="GET"
                      @click.native="onSetAuthorityGroupUsersClick"
                  >
                    권한 그룹 사용자 설정
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="authorityGroupsGrid"
              v-bind="authorityGroupsGridProps"
              @headerCellInfo="onAuthorityGroupsGridHeaderCellInfo"
              @queryCellInfo="onAuthorityGroupsGridQueryCellInfo"
              @recordClick="onAuthorityGroupsGridRecordClick"
            />
          </div>
        </section>
      </article>
    </div>
    <update-authority-group-users-popup
      :group-id="groupIdUpdateAuthorityGroupUsersPopup"
      :group-name="groupNameUpdateAuthorityGroupUsersPopup"
      v-if="visibleUpdateAuthorityGroupUsersPopup"
      @close="onUpdateAuthorityGroupUsersPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: visible;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { orderBy as _orderBy, maxBy as _maxBy } from "lodash";
import { Resize, ExcelExport } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import UpdateAuthorityGroupUsersPopup from "@/views/authority-management/popup/UpdateAuthorityGroupUsersPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "AuthorityGroupUsersManagement",
  components: {
    InputText,
    UpdateAuthorityGroupUsersPopup,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.fetchAuthorityGroups();
  },
  data() {
    return {
      searchGroupsQuery: "",
      authorityGroups: [],

      groupIdUpdateAuthorityGroupUsersPopup: "",
      groupNameUpdateAuthorityGroupUsersPopup: "",
      visibleUpdateAuthorityGroupUsersPopup: false,
    };
  },
  computed: {
    authorityGroupsGridProps() {
      const dataSource = _orderBy(
        (this.authorityGroups || []).map(
          ({ id, name, description, users = [] }, index) => ({
            _rid: index + 1,
            id,
            name,
            description,
            userCount: `${users.length === 0 ? "" : `${users.length} 명`}`,
            userNames: users.map(({ name }) => name).join(", "),
          })
        ),
        "order"
      );

      return {
        allowFiltering: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "id",
            headerText: "ID",
            minWidth: 16,
            textAlign: "Left",
            width: 128,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            field: "name",
            headerText: "그룹명",
            minWidth: 16,
            textAlign: "Left",
            width: 256,
          },
          {
            allowEditing: false,
            field: "description",
            headerText: "설명",
            textAlign: "Left",
          },
          {
            allowEditing: false,
            field: "userNames",
            headerText: "사용자",
            minWidth: 16,
            textAlign: "Left",
            width: 512,
          },
          {
            allowEditing: false,
            field: "userCount",
            headerText: "User Count",
            minWidth: 16,
            textAlign: "Center",
            width: 128,
          },
        ],
        dataSource,
        provides: [Resize, ExcelExport],
        selectionSettings: { type: "Single" },
      };
    },
  },
  methods: {
    async onSearchGroupsClick() {
      await this.fetchAuthorityGroups();
    },
    onSetAuthorityGroupUsersClick() {
      const targetRecords =
        this.$refs["authorityGroupsGrid"].getSelectedRecords() || [];

      if (!targetRecords || targetRecords.length === 0) {
        this.errorToast("권한 그룹을 선택해주세요");
        return;
      }

      this.groupIdUpdateAuthorityGroupUsersPopup = targetRecords[0].id;
      this.groupNameUpdateAuthorityGroupUsersPopup = targetRecords[0].name;
      this.visibleUpdateAuthorityGroupUsersPopup = true;
    },
    onAuthorityGroupsGridHeaderCellInfo(args) {
      const {
        cell: {
          column: { field },
        },
        node,
      } = args;

      if (field === "id") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onAuthorityGroupsGridQueryCellInfo(args) {
      const {
        cell,
        column: { allowEditing, field },
      } = args;

      if (field === "id") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onAuthorityGroupsGridRecordClick(args) {
      const {
        rowData: { id, name },
      } = args;

      this.groupIdUpdateAuthorityGroupUsersPopup = id;
      this.groupNameUpdateAuthorityGroupUsersPopup = name;
      this.visibleUpdateAuthorityGroupUsersPopup = true;
    },
    async onUpdateAuthorityGroupUsersPopupClose() {
      this.visibleUpdateAuthorityGroupUsersPopup = false;
      await this.fetchAuthorityGroups();
    },

    async fetchAuthorityGroups() {
      const searchConents = this.searchGroupsQuery
        ? this.searchGroupsQuery
        : null;

      const authorityGroups =
        (await GolfErpAPI.fetchAuthGroupsBySearchContents(searchConents)) || [];

      this.authorityGroups = authorityGroups;
      this.nextOrder =
        (_maxBy(authorityGroups, "order") || { order: 0 }).order + 1;
    },
    async deleteAuthorityGroup(groupId) {
      await GolfERPService.deleteAuthorityGroup(groupId);
    },
    onClickExcel() {
      this.$refs.authorityGroupsGrid.excelExport();
    },
  },
};
</script>
