<template>
  <div id="popupWrapper">
    <ejs-dialog
      allowDragging="true"
      header="권한 그룹 - 사용자 매핑"
      height="800"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      width="1180"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window authorityGroupUserMapping">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">검색</div>
                    <ul class="content">
                      <li class="item date">
                        <input-text v-model="searchUsersQuery" />
                      </li>
                    </ul>
                    <ul class="content">
                      <li class="item button">
                        <ul class="button">
                          <li class="search">
                            <erp-button
                                button-div="GET"
                                v-on:click.native="onSearchUsersClick"
                            >
                              찾기
                            </erp-button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="usersGrid"
                      v-bind="usersGridProps"
                      @recordClick="usersGridRecordClick"
                    />
                    <ejs-grid-wrapper
                      ref="authorityGroupUsersGrid"
                      v-bind="authorityGroupUsersGridProps"
                      @recordClick="authorityGroupUsersGridRecordClick"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  v-on:click.native="onSaveClick"
              >
               저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import {
  orderBy as _orderBy,
  maxBy as _maxBy,
  differenceBy as _differenceBy,
} from "lodash";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Edit, Resize } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "UpdateAuthorityGroupUsersPopup",
  components: { InputText,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  async mounted() {
    try {
      await this.fetchAll();

      this.show();
    } catch (e) {
      this.hide();
      throw e;
    }
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      groupUsers: [],

      searchUsersQuery: "",
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    usersGridProps() {
      const dataSource = _orderBy(
        (this.users || []).map(({ id, name, email, remarks }, index) => {
          return {
            _rid: index + 1,
            id,
            name,
            email,
            remarks,
          };
        }),
        "order"
      );

      return {
        allowFiltering: false,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "id",
            visible: false,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            field: "name",
            headerText: "이름",
            minWidth: 16,
            textAlign: "Left",
            width: 128,
          },
          {
            allowEditing: false,
            field: "email",
            headerText: "이메일",
            minWidth: 16,
            textAlign: "Left",
            width: 256,
          },
          {
            allowEditing: false,
            field: "remarks",
            headerText: "비고",
            minWidth: 16,
            textAlign: "Left",
            maxLength: 400,
          },
        ],
        dataSource,
        provides: [Edit, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        editSettings: {
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
      };
    },
    authorityGroupUsersGridProps() {
      const dataSource = _orderBy(
        (this.groupUsers || []).map(({ id, name, email, remarks }, index) => {
          return {
            _rid: index + 1,
            id,
            name,
            email,
            remarks,
          };
        }),
        "order"
      );

      return {
        allowFiltering: false,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "id",
            visible: false,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            field: "name",
            headerText: "이름",
            minWidth: 16,
            textAlign: "Left",
            width: 128,
          },
          {
            allowEditing: false,
            field: "email",
            headerText: "이메일",
            minWidth: 16,
            textAlign: "Left",
            width: 256,
          },
          {
            allowEditing: false,
            field: "remarks",
            headerText: "비고",
            minWidth: 16,
            textAlign: "Left",
            maxLength: 400,
          },
        ],
        dataSource,
        provides: [Edit, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        editSettings: {
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    async onSearchUsersClick() {
      await this.fetchAll();
    },
    usersGridRecordClick(args) {
      // recordClick 이벤트가 rowSelect 보다 먼저 실행됨.
      // deleteRecord() method 정상적인 실행을 위해 selectRow() 실행.
      this.$refs["usersGrid"].selectRow(args.rowIndex);

      const { rowData } = args;

      const usersGridRef = this.$refs["usersGrid"];
      const authorityGroupUsersGridRef = this.$refs["authorityGroupUsersGrid"];
      const leftTarget = (usersGridRef.getBatchCurrentViewRecords() || []).find(
        (left) => left._rid === rowData._rid
      );
      if (!leftTarget) {
        return;
      }

      const currentAuthorityGroupAuthoritiesGridRecords =
        authorityGroupUsersGridRef.getBatchCurrentViewRecords() || [];
      const next_rid =
        (
          _maxBy(currentAuthorityGroupAuthoritiesGridRecords, "_rid") || {
            _rid: 0,
          }
        )._rid + 1;
      const nextOrder =
        (
          _maxBy(currentAuthorityGroupAuthoritiesGridRecords, "order") || {
            order: 0,
          }
        ).order + 1;
      const addingRecord = Object.assign({}, rowData);
      addingRecord._rid = next_rid;
      addingRecord.order = nextOrder;

      authorityGroupUsersGridRef.addRecord(addingRecord);
      usersGridRef.deleteRecord();
    },
    authorityGroupUsersGridRecordClick(args) {
      // recordClick 이벤트가 rowSelect 보다 먼저 실행됨.
      // deleteRecord() method 정상적인 실행을 위해 selectRow() 실행.
      this.$refs["authorityGroupUsersGrid"].selectRow(args.rowIndex);

      const { rowData } = args;

      const usersGridRef = this.$refs["usersGrid"];
      const authorityGroupUsersGridRef = this.$refs["authorityGroupUsersGrid"];
      const rightTarget = (
        authorityGroupUsersGridRef.getBatchCurrentViewRecords() || []
      ).find((right) => right._rid === rowData._rid);
      if (!rightTarget) {
        return;
      }

      const currentAuthoritiesGridRecords =
        usersGridRef.getBatchCurrentViewRecords() || [];
      const next_rid =
        (
          _maxBy(currentAuthoritiesGridRecords, "_rid") || {
            _rid: 0,
          }
        )._rid + 1;
      const nextOrder =
        (
          _maxBy(currentAuthoritiesGridRecords, "order") || {
            order: 0,
          }
        ).order + 1;
      const addingRecord = Object.assign({}, rowData);
      addingRecord._rid = next_rid;
      addingRecord.order = nextOrder;

      authorityGroupUsersGridRef.deleteRecord();
      usersGridRef.addRecord(addingRecord);
    },

    async onSaveClick() {
      await this.updateAuthorityGroupUsers(
        this.groupId,
        this.$refs["authorityGroupUsersGrid"].getBatchCurrentViewRecords() || []
      );

      this.infoToast(this.$t("main.popupMessage.saved"));

      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    async fetchAll() {
      const [users, groupUsers] = await Promise.all([
        GolfERPService.fetchUsers({ q: this.searchUsersQuery || undefined }),
        GolfERPService.fetchAuthorityGroupUsers(this.groupId),
      ]);

      this.groupUsers = groupUsers || [];
      this.users = _differenceBy(users || [], groupUsers || [], "id");
    },
    async updateAuthorityGroupUsers(groupId, users) {
      await GolfERPService.updateAuthorityGroupUsers(
        groupId,
        users.map(({ id }) => ({ userId: id }))
      );
    },
  },
};
</script>

<style scoped>
.section-body {display: flex;overflow: hidden;}
</style>
